
    ::v-deep .el-breadcrumb {
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .approved-container {
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px 0;
        .approved-content {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            .approved-detail {
                display: flex;
                flex-direction: column;
                height: 1%;
                flex: 1;
                background: #fff;
                margin-top: 20px;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .detail-top {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .top-left {
                        width: 1%;
                        flex: 1;
                        p {
                            margin: 0;
                            &.title {
                                font-size: 16px;
                            }
                            &.info {
                                color: #666;
                            }
                        }
                    }
                    .top-right {
                        margin-left: 20px;
                        .approved-status {
                            color: #fff;
                            display: inline-block;
                            padding: 0 20px;
                            line-height: 32px;
                            background: #F01414;
                            border-radius: 16px;
                        }
                    }
                }
                .detail-data {
                    display: flex;
                    align-items: center;
                    padding: 20px;
                    border-bottom: 1px solid #EAEAEA;
                    .data-left {
                        color: #fff;
                        padding: 40px 20px;
                        margin-right: 20px;
                        background: linear-gradient(49deg,rgba(107, 192, 255, 1),rgba(58, 140, 246, 1));
                        box-shadow: 0 4px 8px 0 rgba(20,55,186,0.2);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .left-icon {
                            width: 50px;
                            height: 50px;
                            background: #fff;
                            border-radius: 10px;
                            line-height: 50px;
                            text-align: center;
                            .iconfont {
                                color: #409eff;
                                font-size: 28px;
                            }
                        }
                        .left-info {
                            margin-left: 10px;
                            span {
                                display: block;
                                &.score {
                                    font-size: 18px;
                                    line-height: 1.3;
                                    font-weight: 500;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .data-table {
                        width: 1%;
                        flex: 1;
                        display: flex;
                        border-left: 1px solid #EAEAEA;
                        border-bottom: 1px solid #EAEAEA;
                        .row {
                            flex: 1;
                            text-align: center;
                            .item {
                                min-height: 21px;
                                padding: 10px;
                                border-top: 1px solid #EAEAEA;
                                border-right: 1px solid #EAEAEA;
                                .gray {
                                    color: #999;
                                }
                            }
                            &.row-2 {
                                display: flex;
                                flex-direction: column;
                                .item-full {
                                    height: 1%;
                                    flex: 1;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
                .detail-question {
                    .question-type-item {
                        padding: 20px 20px 0;
                        .question-type-title {
                            .title {
                                font-size: 18px;
                                color: #333;
                                font-weight: bold;
                            }
                            .subtitle {
                                font-size: 16px;
                                color: #666;
                            }
                        }
                        .subject-item {
                            padding-bottom: 20px;
                            border-bottom: 1px solid #EAEAEA;
                            .subject-answer {
                                display: flex;
                                margin-top: 15px;
                                .subject-left {
                                    width: 1%;
                                    flex: 1;
                                    .subject-title {
                                        font-size: 16px;
                                        color: #333;
                                        font-weight: 500;
                                        span {
                                            ::v-deep p {
                                                margin: 0;
                                                display: inline;
                                            }
                                            &.score {
                                                color: #409eff;
                                                opacity: 0.7;
                                                margin: 0 10px;
                                            }
                                        }
                                    }
                                    .answer-wrapper {
                                        .answer-box {
                                            display: flex;
                                            margin-top: 10px;
                                        }
                                        .answer-tags {
                                            color: #FF0000;
                                            background: #FFDFDF;
                                            display: inline-block;
                                            padding: 6px 10px;
                                            border-radius: 4px;
                                        }
                                        .answer-content {
                                            width: 1%;
                                            flex: 1;
                                            margin-left: 20px;
                                            .answer {
                                                color: #409eff;
                                                font-weight: 500;
                                            }
                                            .score {
                                                margin-top: 20px;
                                            }
                                        }
                                    }
                                    .answer-others {
                                        display: block;
                                        margin-top: 15px;
                                        padding-left: 20px;
                                        .answer-item {
                                            display: block;
                                            margin-top: 6px;
                                            cursor: default;
                                            &.current {
                                                color: #409EFF;
                                                font-weight: bold;
                                            }
                                        }
                                        .el-radio {
                                            display: flex;
                                            .el-radio__label {
                                                width: 1%;
                                                flex: 1;
                                                white-space: normal;
                                                line-height: 1.2;
                                            }
                                        }
                                    }
                                    .student-answer {
                                        margin-top: 15px;
                                        padding-left: 20px;
                                        strong {
                                            font-size: 18px;
                                            color: #409eff;
                                            margin-right: 10px;
                                            &.current {
                                                color: #F01414;
                                            }
                                        }
                                        &.current {
                                            strong {
                                                color: #F01414;
                                            }
                                        }
                                    }
                                }
                                .subject-right {
                                    margin-left: 20px;
                                }
                            }
                            .subject-analysis {
                                margin: 15px 0 0;
                                background: #F5F5F5;
                                padding: 15px;
                                span {
                                    ::v-deep p {
                                        margin: 0;
                                        display: inline;
                                    }
                                }
                            }
                        }
                    }
                    .detail-commit {
                        text-align: center;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
